<template>
    <r-e-dialog title="编辑租户信息" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer top="10vh" width="400px"
            @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="tenantData" :rules="rules">
            <div class="flex">
                <el-form-item label="姓名" prop="name">
                    <el-input type="text" v-model="tenantData.name" :maxlength="99" placeholder="请输入姓名"></el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="手机号" prop="tel1">
                    <el-input type="text" v-model="tenantData.tel1" :maxlength="15" placeholder="请输入手机号"></el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="身份证" prop="idcard">
                    <el-input type="text" v-model="tenantData.idcard" :maxlength="18" placeholder="请输入身份证"></el-input>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="租户类型" prop="type">
                    <el-select v-model="tenantData.type" style="width: 200px;" placeholder="请选择">
                        <el-option  label="个人" value="个人"/>
                        <el-option  label="法人" value="法人"/>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {updateLeasor} from "@/api/tenant-management";
    import { MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    export default {
        name: "dialog-save-tenant",
        data(){
            return {
                dialogVisible: false,
                rules:{
                    name: [ { required: true, message: '姓名不能为空', trigger: 'blur'} ],
                    tel1: [ { required: true, message: '电话不能为空', trigger: 'blur'} ],
                    idcard: [ { required: true, message: '身份证不能为空', trigger: 'blur'}],
                    type: [ { required: true, message: '请选择用户类型', trigger: 'change'}],
                },
                tenantData:{}
            };
        },
        methods:{
            openDialog(data){
                console.log(data);
                if(data){
                    let {uuid, name, idcard, tel1, type} = data;
                    this.tenantData = {uuid, name, idcard, tel1, type}
                }
                this.dialogVisible=true;
            },
            clickSubmit(){
                let that=this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                         let {uuid, name, idcard, tel1,type}=that.tenantData;
                        updateLeasor({uuid,name, idcard, tel1,type}).then(res=>{
                            MessageSuccess("编辑租户信息成功");
                            that.$emit("closeDrawer");
                            loading.close();
                        }).catch(err=>{
                            MessageError("编辑租户信息失败");
                            loading.close();
                        });

                    }
                });
            },
            clickCancel(){
                this.dialogVisible=false;
                this.tenantData = {name:"", idcard:"", tel1:""}
            }
        }
    }
</script>

<style scoped>

</style>